import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl = "https://selbysellssd.com/bankers-hill-is-great-place-to-live";

  return (
    <LayoutWrapper
      title="5 Reasons San Diego’s Bankers Hill Is a Great Place to Live"
      desc="If you’re considering moving to San Diego, look no further than Bankers Hill! Read on as we’ve gathered 5 reasons why Bankers Hill is a great place to live!"
      headerLogo="../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#1A1A1A] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              5 Reasons Why San Diego’s Bankers Hill Is a Great Place to Live
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By The Selby Team
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  February 23, 2023
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_5 Reasons Why San Diego’s Bankers Hill Is a Great Place to Live.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                If you’re considering moving to San Diego, but don’t know which neighborhood to
                choose, look no further than Bankers Hill.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                With its lively social scene, a blend of old and new architecture, stunning views,
                and close proximity to all the must-have amenities, we’re not surprised that more
                homeowners are choosing Bankers Hill. It was hard to whittle our list down, but here
                are five reasons we think San Diego’s Bankers Hill is the place to live in 2023.{" "}
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Instagrammable Views
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Nestled on a hill with a stunning view of San Diego Bay, Balboa Park, and downtown,
                Bankers Hill offers breathtaking views. Whether you enjoy watching the sunrise with
                a warm cup of coffee or unwinding with a glass of wine and a sunset, Bankers Hill’s
                views are designed to impress.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Balboa Park
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Bankers Hill is home to San Diego’s beloved Balboa Park, a sprawling green space
                that attracts visitors worldwide. This park offers miles of walking and bike trails,
                gardens, museums, and even restaurants. With its central location and easy
                walkability, you can easily take a leisurely stroll through the park several times a
                week to recharge your batteries, disconnect, and unwind. Plus, nearby San Diego Bay
                is just a stone’s throw away, which means even more opportunities for relaxation and
                adventure.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Arts and Culture
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Bankers Hill is also home to numerous museums and art galleries, including The San
                Diego Museum of Art and the Museum of Photographic Arts. And don’t forget about
                seasonal festivals, including the Bankers Hill Fall Festival, where local
                restaurants, spas, and galleries offer their enviable tastes, holistic wellness
                products, and stunning artwork.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                It’s All About Location
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Another thing that makes Bankers Hill so appealing is its proximity to downtown San
                Diego. It’s the perfect spot for people who crave the excitement of big city
                nightlife but prefer not to live in the heart of the action. What’s more, this
                community is also not far from other hotspot destinations like La Jolla, Mission
                Beach, and Coronado.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Old Meets New
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Diverse architecture is another thing we love about Bankers Hill. Many of the
                historic homes were designed by famous architects like Irving Gill and date back to
                the late 1800s, offering a nice contrast to the newer residences, condos, and
                apartments that have sprung up in the last decade—especially around Balboa Park.
                It’s a nice, seamless blend of old and new that’s charming and just another reason
                to fall in love with this community.
              </p>

              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Let’s Connect
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The Selby Team is about family—and we’re ready to help yours move forward. Our team
                of friendly and experienced professionals is dedicated to making your dream home a
                reality, whether here, there, at Bankers Hill, or anywhere!{" "}
                <ContactSlideoutLink text="Contact The Selby Team" /> to get started on finding your
                new home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
